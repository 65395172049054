<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/perfil-exposicao">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/perfil-exposicao"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <h5 class="mb-4">{{ title }}</h5>
                    <div class="field col-6">
                        <label for="name">Nome</label>
                        <InputText
                            id="name"
                            v-model.trim="form.nome"
                            required="true"
                            autofocus
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.nome }"
                        />
                    </div>
                    <div class="field col-6">
                        <label for="cliente">Cliente</label>
                        <DropdownCliente id="cliente" v-model="form.cliente" :class="{ 'p-invalid': submitted && !form.cliente }" />
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import DropdownCliente from '../clientes/components/DropdownCliente.vue';
export default {
    components: {
        DropdownCliente
    },
    data() {
        return {
            form: {
                customerId: null
            },
            submitted: false,
            service: null
        };
    },
    created() {
        this.service = new SesmtService('/perfil-exposicao');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editando Perfil Exposição' : 'Adicionando Perfil Exposição';
        }
    },
    watch: {
        'form.cliente'(newValue) {
            this.form.customerId = newValue?.id;
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.form.nome) return true;
        }
    }
};
</script>